.contact{
display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 4px;
  width: fit-content;
  background-color: var(--primary-color);
  padding: 6px 12px;
  border-radius: 50px;

  &__name{
    font-weight: bold;
  }
}
