.project {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  border-radius: 12px;
  background-color: var(--secondary-background-color);
  padding: 16px;
  gap: 8px;

  &__name {
    font-weight: bold;
    font-size: 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    width: fit-content;

    &__url{
      font-weight: bold;
      overflow-wrap: anywhere;
    }
  }
}
